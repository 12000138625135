const Comming = () => {
  return (
    <div className="headline">
      <h3 className="headline--title">
        <i>"Que un lápiz y un pincel sean la puerta del infinito mismo"</i>
      </h3>
      <div className="social-icons">
        <a
          href="https://www.instagram.com/vhelkanzoto/"
          target={"_blank"}
          rel="noreferrer"
        >
          <i className="fab fa-instagram"></i>
        </a>
        <a
          href="https://tiktok.com/@vhelkanzoto"
          target={"_blank"}
          rel="noreferrer"
        >
          <i className="fab fa-tiktok"></i>
        </a>
        <a
          href="mailto:vhelkannzoto@outlook.es"
          target={"_blank"}
          rel="noreferrer"
        >
          <i className="far fa-envelope"></i>
        </a>
        <a
          href="https://twitter.com/VhelkanZoto"
          target={"_blank"}
          rel="noreferrer"
        >
          <i className="fa-brands fa-x-twitter"></i>
        </a>
      </div>
    </div>
  );
};

export default Comming;
