import Container from "react-bootstrap/Container";
import { Navbar, Nav } from "react-bootstrap";
import logo from "../logo.png";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import { LinkContainer } from "react-router-bootstrap";

const NavBar = () => {
  const location = useLocation();
  const [hideNavbar, setHideNavbar] = useState(true);

  useEffect(() => {
    if (location.pathname !== "/") {
      setHideNavbar(false);
    } else {
      setHideNavbar(true);
    }
  }, [location]);

  return (
    <>
      {hideNavbar ? null : (
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="dark"
          className="bg-body-tertiary"
        >
          <Container>
            <LinkContainer to={"/"}>
              <Navbar.Brand href="#home">
                <img
                  alt=""
                  src={logo}
                  width="30"
                  height="30"
                  className="d-inline-block align-top"
                />{" "}
                <span className="vhelkan-title">Vhelkan Zoto</span>
              </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link className="not-available">Poeta</Nav.Link>
                <Nav.Link className="not-available">Artista</Nav.Link>
                <LinkContainer to={"/books"}>
                  <Nav.Link>Escritor</Nav.Link>
                </LinkContainer>
                <Nav.Link className="not-available">Tatuador</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      )}
    </>
  );
};

export default NavBar;
