import "./assets/styles/main.scss";
import { Container } from "react-bootstrap";
// import NavBar from './components/Navbar';
import Hero from "./components/Hero";
import Footer from "./components/Footer";
import Books from "./components/Home/Books";

import NavBar from "./components/Navbar";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <Container>
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/books" element={<Books />} />
        </Routes>
      </Router>
      <Footer />
    </Container>
  );
}

export default App;
