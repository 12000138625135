import Comming from "./Comming";
import { Link } from "react-router-dom";
const Hero = () => {
  return (
    <>
      <div className="hero-container">
        <div className="hero-image">
          <div className="hero-text">
            <h1 className="vhelkan-title">Vhelkan Zoto</h1>
            <h4 className="vhelkan-subtitle">
              <span className="not-available">Poeta</span>
              <span className="not-available">Artista</span>
              <span>
                {/* <a href="#books">Escritor</a> */}
                <Link to={"/books"}>Escritor</Link>
              </span>
              <span className="not-available">Tatuador</span>
            </h4>
          </div>
        </div>
      </div>
      <Comming />
    </>
  );
};

export default Hero;
