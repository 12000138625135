const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer id="footer">
            <ul className="copyright">
                <li>&copy; {currentYear} Vhelkan Zoto | Todos los derechos reservados. </li>
            </ul>
            <span className="jaes-made-it">
                Desarrollador por{" "}
                <a
                    href="https://www.jaesmadeit.com/"
                    target={"_blank"}
                    rel="noreferrer"
                >
                    JAES Made It
                </a>
            </span>
        </footer>
    );
};

export default Footer;