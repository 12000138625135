const books = [
  {
    title: "Oscuridad",
    img: "./assets/img/books/vhelkan-zoto-oscuridad.jpeg",
    url: "https://www.autoreseditores.com/libro/23215/vhelkan-zoto/oscuridad.html",
    description:
      "Una historia narrada entre versos poéticos dramáticos que intenta expresar el sentimiento de dos seres fundidos queriéndose separar uno del otro. Mientras que a medida del tiempo describen su vida, sentimientos, deseos y forma de ver al mundo, trata de introducir a la humanidad en realidad misma del ser, tratando de concientizar el verdadero valor de la vida y el significado de la muerte.",
  },
  {
    title: "Ella",
    img: "./assets/img/books/vhelkan-zoto-ella.jpeg",
    url: "https://www.autoreseditores.com/libro/23216/vhelkan-zoto/ella.html",
    description:
      "Y el silencio deslumbró los pasillos que recorríamos en la habitación de madera, donde tantas veces en cama fundimos nuestros cuerpos, entre palabras de amor, que llenaban el alma vacía de dos cuerpos, de dos seres que en su momento se amaron con tanta fuerza, sin saber que su llama se consumió",
  },
  {
    title: "La Razón Del Lobo",
    img: "./assets/img/books/vhelkan-zoto-la-razon-del-lobo.jpeg",
    url: "https://www.autoreseditores.com/libro/23217/vhelkan-zoto/la-razon-del-lobo.html",
    description:
      "Musas... como describí el día viernes 9 de octubre del 2020, en el evento del VII encuentro municipal a la sombra del tatama, en su modalidad y reconocimiento a los poetas y escritores del municipio de santuario Risaralda, al cual asistí por primera vez y compartí un poco de mis conocimientos en este campo, que realmente es empirismo...",
  },
  {
    title: "Yo y mi soledad",
    img: "./assets/img/books/vhelkan-zoto-yo-y-mi-soledad.jpeg",
    url: "https://www.autoreseditores.com/libro/23218/vhelkan-zoto/yo-y-mi-soledad.html",
    description:
      "Que dijera yo, si mas simple fue el tiempo que se ha marchado sin saber cuanto he perdido, cuanto he logrado, pero todo se queda en silencio?",
  },
  {
    title: "Sangre de un Poeta",
    img: "./assets/img/books/vhelkan-zoto-sangre-de-un-poeta.jpeg",
    url: "https://www.autoreseditores.com/libro/23220/vhelkan-zoto/sangre-de-un-poeta.html",
    description:
      "No son solo textos Son trozos de mi alma Que mi corazón boto hacia el vacío y el abandono Todo sin un ¿Por qué?, pero si por un quien. Agradesco cada detalle que me has dado, Y también agradesco la muerte que me has causado.",
  },
];

function Books() {
  return (
    <div className="row row-cols-1 row-cols-md-2 g-4" id="books">
      {books.map((book) => (
        <div className="col" key={book.title}>
          <div className="card mb-3">
            <div className="row g-0">
              <div className="col-md-4 text-center">
                <img
                  src={book.img}
                  className="img-fluid rounded-start"
                  alt="..."
                />
              </div>
              <div className="col-md-8">
                <div className="card-body">
                  <h5 className="card-title">{book.title}</h5>
                  <p className="card-text">{book.description}</p>
                  <div className="card-text">
                    <a
                      href={book.url}
                      className="btn btn-dark"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fas fa-shopping-cart"></i> Comprar
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Books;
